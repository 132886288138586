import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Contact from "./Contact";
import Alert from "./components/Alert";
import * as serviceWorker from "./serviceWorker";

/* uncomment for testing locally
window.rjincformSettings = {
	id: "contact",
};
*/

const target = document.getElementById("rjincform-root");
if (target) {
	if (window.rjincformSettings) {
		const { id } = window.rjincformSettings;
		if (id === "contact") {
			ReactDOM.render(
				<React.StrictMode>
					<Contact />
				</React.StrictMode>,
				target
			);
		} else {
			ReactDOM.render(
				<React.StrictMode>
					<Alert data={{ type: "error", text: "An error has occurred." }} />
				</React.StrictMode>,
				target
			);
		}
	} else {
		ReactDOM.render(
			<React.StrictMode>
				<Alert data={{ type: "error", text: "An error has occurred." }} />
			</React.StrictMode>,
			target
		);
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
