import React, { useState, useEffect, Fragment } from "react";
import { API_URL } from "./helpers";
import Select from "./components/Select";
import Input from "./components/Input";
import Textarea from "./components/Textarea";
import Msg from "./components/Msg";
import Submit from "./components/Submit";
import Alert from "./components/Alert";
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

const Contact = () => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});
	const [countries, setCountries] = useState([]);
	const [msg, setMsg] = useState({});
	const [alert, setAlert] = useState({
		type: "working",
		text: "",
	});

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const url = new URL(`${API_URL}/`);
		const params = { f: "inquireInit" };
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setCountries(json.countries);
				setAlert({});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/?f=inquire`);
		let data = new FormData();
		data.append("fname", names.fname || "");
		data.append("lname", names.lname || "");
		data.append("email", names.email || "");
		data.append("phone", names.phone || "");
		data.append("country", names.country || "");
		data.append("city", names.city || "");
		data.append("st", names.st || "");
		data.append("comments", names.comments || "");

		try {
			const response = await fetch(url, {
				method: "POST",
				body: data,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setMsg({});
				setAlert({
					type: "success",
					text: json.text,
				});
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<Fragment>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<form method="post" action="/" onSubmit={(e) => handleSubmit(e)}>
					<div className="two">
						<div>
							<Input
								type="text"
								label="First Name"
								req={true}
								name="fname"
								value={names.fname}
								update={handleUpdate}
								errors={errors}
								autocomplete="given-name"
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Last Name"
								req={true}
								name="lname"
								value={names.lname}
								update={handleUpdate}
								errors={errors}
								autocomplete="family-name"
							/>
						</div>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="Email Address"
								req={true}
								name="email"
								value={names.email}
								update={handleUpdate}
								errors={errors}
							/>
						</div>
						<div>
							<Input
								type="text"
								label="Phone"
								req={false}
								name="phone"
								value={names.phone}
								update={handleUpdate}
								errors={errors}
								autocomplete="tel"
							/>
						</div>
					</div>

					<div className="one">
						<Select
							label="Country"
							req="true"
							name="country"
							value=""
							data={countries}
							update={handleUpdate}
							errors={errors}
							autocomplete="country-name"
						/>
					</div>

					<div className="two">
						<div>
							<Input
								type="text"
								label="City"
								req={true}
								name="city"
								value={names.city}
								update={handleUpdate}
								errors={errors}
								autocomplete="address-level2"
							/>
						</div>
						<div>
							<Input
								type="text"
								label="State / Region"
								req={false}
								name="st"
								value={names.st}
								update={handleUpdate}
								errors={errors}
								autocomplete="address-level1"
							/>
						</div>
					</div>

					<div className="one">
						<Textarea
							label="Question / Comment"
							req={true}
							name="comments"
							value={names.comments}
							update={handleUpdate}
							errors={errors}
						/>
					</div>

					<div className="msg-submit">
						{msg.type && <Msg data={msg} />}

						<div
							className={msg.type === "working" ? "hidden" : "submit-container"}
						>
							<Submit name="Submit Inquiry" icon={faChevronCircleRight} />
						</div>
					</div>

					<p>
						We will not share your email address with third-parties. For more
						information on how we use your information, see our{" "}
						<a href="/privacy-policy/">Privacy Policy</a>.
					</p>
				</form>
			)}
		</Fragment>
	);
};

export default Contact;
